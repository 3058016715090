import { lighten, makeStyles } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { forwardRef } from "react";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      ...theme.typography.caption,
      backgroundColor: lighten(theme.colors.grey, 0.6),
      borderRadius: 4,
      color: theme.palette.text.primary,
      display: "inline-block",
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: "16px",
      padding: theme.spacing(0, 0.5),
      textTransform: "uppercase",
      pointerEvents: "auto",
      cursor: "default",
    },
    paid: {
      backgroundColor: theme.colors.logo.corn,
    },
    medium: {},
    small: {},
    slim: {},
  }),
  {
    classNamePrefix: "EditionBadgeBase",
  }
);

export type EditionBadgeBaseJSSClassKey = keyof ReturnType<typeof useStyles>;

export type EditionBadgeBaseProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "ref"
> & {
  classes?: Partial<ClassNameMap<EditionBadgeBaseJSSClassKey>>;
  size?: "slim" | "small" | "medium";
  paid?: boolean;
};

export const EditionBadgeBase = forwardRef<HTMLDivElement, EditionBadgeBaseProps>(
  ({ className, classes: extClasses, size = "medium", paid, children, ...rest }, ref) => {
    const classes = useStyles({
      classes: extClasses,
    });

    /********************/
    /*       JSX        */
    /********************/

    return (
      <div
        className={clsx(classes.root, className, {
          [classes.paid]: paid,
          [classes.medium]: size === "medium",
          [classes.small]: size === "small",
          [classes.slim]: size === "slim",
        })}
        ref={ref}
        {...rest}
      >
        {children}
      </div>
    );
  }
);

EditionBadgeBase.displayName = "EditionBadgeBase";
