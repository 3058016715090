import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { createContext, forwardRef, TouchEventHandler, useCallback, useContext } from "react";
import { OPACITY_PULSE } from "../../AppTheme";
import { useTeamState } from "../../hooks/atoms/useTeam";
import { EDITION_META } from "../../reclaim-api/team/Team.consts";
import { ReclaimEdition } from "../../reclaim-api/team/Team.types";
import { EditionBadgeBase, EditionBadgeBaseProps } from "./EditionBadgeBase";

const useStyles = makeStyles(
  (theme) => ({
    ...OPACITY_PULSE.keyframes,
    root: {},
    loading: {
      ...OPACITY_PULSE.elementStyles,
      width: 50,
    },
  }),
  {
    classNamePrefix: "EditionBadge",
  }
);

export type EditionBadgeProps = Omit<EditionBadgeBaseProps, "paid" | "classes"> & {
  edition: ReclaimEdition | undefined;
  EditionBadgeBaseClasses?: EditionBadgeBaseProps["classes"];
  useShortLabel?: boolean;
  showIfTrial?: boolean;
};

export type EditionBadgeContextData = { tooltipOpen: boolean; setTooltipOpen: (open: boolean) => void };
export const EditionBadgeContext = createContext<EditionBadgeContextData>({
  tooltipOpen: false,
  setTooltipOpen: () => void 0,
});

export const EditionBadge = forwardRef<HTMLDivElement, EditionBadgeProps>(
  ({ edition, className, EditionBadgeBaseClasses, useShortLabel, showIfTrial, ...rest }, ref) => {
    const classes = useStyles();
    const { tooltipOpen, setTooltipOpen } = useContext(EditionBadgeContext);
    const { team } = useTeamState();

    const handleTouchEndCapture = useCallback<TouchEventHandler<HTMLDivElement>>(
      (e) => {
        if (!tooltipOpen) {
          // preventDefault on touchEnd blocks click event
          e.preventDefault();
          e.stopPropagation();
          setTooltipOpen(true);
        } else setTooltipOpen(false);
      },
      [setTooltipOpen, tooltipOpen]
    );

    const loading = !edition;

    return (
      <EditionBadgeBase
        onTouchEndCapture={handleTouchEndCapture}
        className={clsx(classes.root, className, { [classes.loading]: loading })}
        paid={edition !== "LITE" && edition !== "ASSISTANT"}
        classes={EditionBadgeBaseClasses}
        ref={ref}
        {...rest}
      >
        {edition ? (
          (() => {
            const { shortLabel, label, isTrial } = EDITION_META[edition];
            return (
              <>
                {useShortLabel ? shortLabel || label : label}
                {showIfTrial && (isTrial || team?.pricingSummary.subscriptionStatus === "TRIALING") ? " trial" : ""}
              </>
            );
          })()
        ) : (
          <>&nbsp;</>
        )}
      </EditionBadgeBase>
    );
  }
);

EditionBadge.displayName = "EditionBadge";
